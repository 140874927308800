import React from "react"
import { Link } from "gatsby"
import { Box, Badge, Image, Grid } from "@chakra-ui/core"
import Layout from "../../../components/layoutSurpriseCustom"
import BgImg from "../../../images/RomansPracticeBig.jpeg"
import Romans from "../../../images/practiceTitle.jpeg"
import Friends from "../../../images/friendsDayEvent.jpeg"
import More from "../../../images/moreSermons.jpg"
import SEO from "../../../components/seo"
import { css } from "@emotion/core"

function SermonBox({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as={Link}
      to={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

function SermonBoxExternal({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as="a"
      href={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

const IndexPage = () => (
  <Layout
    ImgSrc={BgImg}
    MainText="Romans: Practice"
    SecondText="Check out sermons from our Romans Sermon Series"
  >
    <SEO title="Sermons - Romans: Practice" />
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
      ]}
      gap={10}
    >
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="Now..."
        sermonSeries="Romans: Practice"
        sermonLink="/surprise/sermons/now"
        date="April 10, 2022"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="Appearance vs. Reality"
        sermonSeries="Romans: Practice"
        sermonLink="/surprise/sermons/appearance-vs-reality"
        date="April 3, 2022"
        pastor="Pastor Jackie"
      />

      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="FOR"
        sermonSeries="Romans: Practice"
        sermonLink="/surprise/sermons/for"
        date="March 27, 2022"
        pastor="Pastor Tyler"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="The Mistake of Majoring on the Minors"
        sermonSeries="Romans: Practice"
        sermonLink="/surprise/sermons/majoring-on-the-minors"
        date="March 20, 2022"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="Wake Up!"
        sermonSeries="Romans: Practice"
        sermonLink="/surprise/sermons/wake-up"
        date="March 13, 2022"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="The Christian and Politics"
        sermonSeries="Romans: Practice"
        sermonLink="/surprise/sermons/christian-and-politics"
        date="March 6, 2022"
        pastor="Chad Garrison"
      />
      <SermonBox
        imageUrl={Friends}
        imageAlt="Friends Day"
        sermonTitle="Friends Day"
        sermonSeries="Friends Day"
        sermonLink="/surprise/sermons/friends-day"
        date="February 27, 2022"
        pastor="Jackie Allen"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="Character Counts"
        sermonSeries="Romans: Practice"
        sermonLink="/surprise/sermons/character-counts"
        date="February 20, 2022"
        pastor="Jackie Allen"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="Making Jesus Known"
        sermonSeries="Romans: Practice"
        sermonLink="/surprise/sermons/making-jesus-known"
        date="February 13, 2022"
        pastor="Kevin Ezell"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="The Christian and the Church"
        sermonSeries="Romans: Practice"
        sermonLink="/surprise/sermons/christian-and-the-church"
        date="February 6, 2022"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="Worship Through the Week"
        sermonSeries="Romans: Practice"
        sermonLink="/surprise/sermons/worship-through-the-week"
        date="January 30, 2022"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="An Interlude of Praise"
        sermonSeries="Romans: Practice"
        sermonLink="/surprise/sermons/interlude"
        date="January 23, 2022"
        pastor="Pastor Jackie"
      />

      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="Understanding God's Plan"
        sermonSeries="Romans: Practice"
        sermonLink="/surprise/sermons/understanding-gods-plan"
        date="January 16, 2022"
        pastor="Andrew Puett"
      />

      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="Rolling Through Romans"
        sermonSeries="Romans: Practice"
        sermonLink="/surprise/sermons/rolling-through-romans"
        date="January 9, 2022"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={More}
        imageAlt="view more sermons"
        sermonTitle="View More Sermons"
        sermonLink="/surprise/sermons"
        sermonSeries="All Sermons"
        pastor="Cross Church Surprise"
      />
    </Grid>
  </Layout>
)

export default IndexPage
